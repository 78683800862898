import * as constants from '@/constants';
import moment from 'moment-timezone';
import i18n from '@/languages';

const saveUserToLocaleState = (user) => {
    if (user) {
        localStorage.setItem(constants.USER_STORAGE_KEY, JSON.stringify(user));
        localStorage.setItem(constants.SELECTED_LANGUAGE, user.language);
        i18n.locale = user.language;
        moment.tz.setDefault(user.timezone);
    } else {
        localStorage.removeItem(constants.USER_STORAGE_KEY);
    }
};

export default {
    state: {
        user: {
            language: 'en',
        },
        prevDetectedTz: localStorage.getItem(constants.PREV_DETECTED_TZ_STORAGE_KEY),
        noTzUpdates: localStorage.getItem(constants.NO_TZ_UPDATES_STORAGE_KEY) === 'true',
    },
    mutations: {
        [constants.PROFILE_SET_LOCALE](state, locale) {
            state.user.language = locale;
            saveUserToLocaleState(state.user);
        },
        [constants.PROFILE_SET_USER](state, user) {
            if (!user) {
                // never set user to null as it led to null-safety bugs
                state.user = {
                    language: 'en',
                };
                // state.prevDetectedTz = null;
                // state.noTzUpdates = null;
            } else {
                state.user = user;
            }
            saveUserToLocaleState(user);
        },
        [constants.PROFILE_SET_UPDATES](state, updates) {
            state.user = { ...state.user, ...updates };
            moment.locale(state.user.language);
            saveUserToLocaleState(state.user);
        },
        [constants.PROFILE_SET_NO_TZ_UPDATES](state, noTzUpdates) {
            state.noTzUpdates = noTzUpdates;
            localStorage.setItem(constants.NO_TZ_UPDATES_STORAGE_KEY, noTzUpdates);
        },
        [constants.PROFILE_SET_PREV_TZ_DETECTED](state, newTz) {
            state.prevDetectedTz = newTz;
            localStorage.setItem(constants.PREV_DETECTED_TZ_STORAGE_KEY, newTz);
        },
    },
    actions: {
        async [constants.PROFILE_LOAD]({ commit, rootGetters }) {
            if (!rootGetters.isAuthenticated) return;
            const user = JSON.parse(localStorage.getItem(constants.USER_STORAGE_KEY));
            if (user) {
                commit(constants.PROFILE_SET_USER, user);
            }
        },
        async [constants.PROFILE_FETCH]({ commit, dispatch }) {
            let response = await dispatch(constants.REQUEST, {
                url: 'profile',
            });
            if (response.status === 200) {
                commit(constants.PROFILE_SET_USER, response.data.data);
            }

            return response;
        },
        async [constants.USER_PATCH]({ dispatch, commit, rootState }, data) {
            const response = await dispatch(constants.REQUEST, {
                url: 'user',
                method: 'patch',
                baseURL: rootState.config.rules.api.url,
                data,
            });
            if (response.status === 200) {
                commit(constants.PROFILE_SET_UPDATES, data);
            }
            return response;
        },
        async [constants.PROFILE_UPDATE]({ commit, dispatch, state, rootState }, data) {
            let response = await dispatch(constants.REQUEST, {
                method: 'put',
                // Update the client profile in case of guest/ (on public profile)
                baseURL: rootState.request.baseURL.replace('guest', 'client'),
                url: 'profile',
                data: {
                    ...data,
                    email: data.email || state.user.email,
                    avatar: data.avatar?.includes('//') ? undefined : data.avatar,
                },
            });
            if (response.status === 200) {
                commit(constants.PROFILE_SET_USER, response.data.data);
            }
            return response;
        },
        async [constants.PROFILE_ONBOARDING_UPDATE]({ dispatch, rootState }) {
            let response = await dispatch(constants.REQUEST, {
                method: 'put',
                baseURL: rootState.request.baseURL.replace('guest', 'client'),
                url: 'profile/onboarding',
            });

            return response;
        },
        async [constants.PROFILE_PASSWORD]({ dispatch }, data) {
            let response = await dispatch(constants.REQUEST, {
                method: 'put',
                url: 'profile/password',
                data,
            });
            return response;
        },
        async [constants.PROFILE_EMAIL_VERIFY]({ dispatch }, fullPath) {
            let response = await dispatch(constants.REQUEST, {
                method: 'post',
                url: fullPath.replace('professional/', ''),
            });
            return response;
        },
        async [constants.PROFILE_EMAIL_RESEND]({ dispatch }) {
            let response = await dispatch(constants.REQUEST, {
                method: 'post',
                url: 'profile/email/resend',
            });
            return response;
        },
        async [constants.PROFILE_TOGGLE_VISIBILITY]({ dispatch, commit }) {
            let response = await dispatch(constants.REQUEST, {
                method: 'post',
                url: 'profile/visible',
            });
            if (response.status === 200) {
                commit(constants.PROFILE_SET_USER, response.data.data);
            }
            return response;
        },
        async [constants.PROFILE_UPDATE_TIMEZONE]({ dispatch, state, rootState }, timezone) {
            await dispatch(constants.PROFILE_UPDATE, {
                ...state.user[rootState.config.theme],
                timezone,
            });
            location.reload();
        },
        [constants.PROFILE_SUGGEST_TZ]({ commit, dispatch, state }) {
            if (state.noTzUpdates) return null;

            // timezone 'Etc/UTC' is set by the backend by default
            if (state.user.timezone === 'Etc/UTC') {
                if (state.user.email) {
                    dispatch(constants.PROFILE_UPDATE_TIMEZONE, moment.tz.guess());
                } else {
                    console.warn('Tried updating timezone while unauthenticated');
                }
            }

            if (!state.prevDetectedTz)
                localStorage.setItem(constants.PREV_DETECTED_TZ_STORAGE_KEY, moment.tz.guess());

            const tzGuess = moment.tz.guess();
            const prevDetectedTz = state.prevDetectedTz;
            commit(constants.PROFILE_SET_PREV_TZ_DETECTED, tzGuess);

            if (tzGuess === prevDetectedTz || tzGuess === state.user.timezone) return null;

            return tzGuess;
        },
    },
};
